/* global google, console */

'use strict';

// Elements
var mapElm = document.getElementById('map');
var areaElm = document.getElementById('area');
var switcher = document.getElementById('switcher');
var getPriceBtn = document.getElementById('getPrice');
var resultContainer = document.getElementById('results-container');
var priceElm = document.getElementById('price');
var startOverBtn = document.getElementById('start-over');
var clearDrawingsBtn = document.getElementById('clearDrawings');
var calcWrapper = document.querySelector('.calc-wrapper');
var floorSelector = document.getElementById('floors');
var address = document.getElementById('address');
var drawLine = document.getElementById('drawLine');
var searchLocation = document.getElementById('search-location');
var userMail = document.getElementById('user-email');
var demoRequest = document.getElementById('demo-request');
var thanksMsg = document.querySelector('.msg');
var errorMsg = document.querySelector('.error');

var oneDetectorPrice = 60;
var allOverlays = [];
var selectedFloor = 1;
var lowerBound = 0.161458656;
var upperBound = 0.179398507;

var price = '';
var upperPrice, lowerPrice;

var selectedShape;
var area_m2;
var sqFt;
var clientId;

var locations = [];
var locCoords;

// Vars related with the google map
var geocoder = new google.maps.Geocoder();
var map = new google.maps.Map(mapElm, {
  center: { lat: 37.753274, lng: -122.458551 },
  zoom: 13
});

var drawingManager = new google.maps.drawing.DrawingManager({
  drawingMode: null,
  drawingControl: true,
  drawingControlOptions: {
    position: google.maps.ControlPosition.RIGHT_BOTTOM,
    drawingModes: ['polygon']
  },
  polygonOptions: {
    fillColor: '#dd4B37',
    strokeColor: "#faa424",
    strokeWeight: 1,
    fillOpacity: 0.3,
    editable: true
  }
});


var searchBox = new google.maps.places.SearchBox(address);



function convertToSquareFoot() {
  return (area_m2 * 10.764);
}

function nearestMultiple(number, multiplier) {
  return Math.ceil(number / multiplier) * multiplier;
}


function getSiblings(element) {
  if (element !== null) {
    element.classList.remove('is-active');
    var siblings = Array.prototype.filter.call(element.parentNode.children, function(child) {
      return child !== element;
    });
    siblings.pop().classList.add('is-active');
  }

}

function goToLocation() {
  geocoder.geocode({ 'address': address.value }, function(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      map.setCenter(results[0].geometry.location);
      new google.maps.Marker({
        position: results[0].geometry.location,
        map: map
      });
    } else {
      console.log('Geocode was not successful for the following reason: ' + status);
    }
  });
}

function deleteAllShape() {
  for (var i = 0; i < allOverlays.length; i++) {
    allOverlays[i].overlay.setMap(null);
  }
  allOverlays = [];
}

function calcar() {
  google.maps.geometry.spherical.computeArea(selectedShape.getPath());
}

function clearSelection() {
  if (selectedShape) {
    selectedShape.setEditable(false);
    selectedShape = null;
  }
}

function setSelection(shape) {
  clearSelection();
  selectedShape = shape;
  shape.setEditable(true);
  google.maps.event.addListener(shape.getPath(), 'set_at', calcar);
  google.maps.event.addListener(shape.getPath(), 'insert_at', calcar);
}

function getPrice() {
  upperPrice = (nearestMultiple((((area_m2 * upperBound))), oneDetectorPrice)) * selectedFloor;
  lowerPrice = (nearestMultiple((((area_m2 * lowerBound))), oneDetectorPrice)) * selectedFloor;

  if (upperPrice === lowerPrice) {
    price = upperPrice;
  } else {
    price = lowerPrice + ' - ' + upperPrice;
  }
}

function generatePassword() {
  var length = 8, charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var randomPass = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    randomPass += charset.charAt(Math.floor(Math.random() * n));
  }
  return randomPass;
}

function makePostRequest() {
  var places = searchBox.getPlaces();
  var area_string = '';
  if (area_m2 !== undefined) {
    area_string = area_m2.toLocaleString('en-US', { minimumFractionDigits: 0 }) + 'sq.m.';
  }

  var location = address.value;
  if (places !== undefined) {
    var searchedPlace = places.pop();
    if (searchedPlace !== undefined) {
      // Performing a POST request
      location = searchedPlace.formatted_address;
    }
  }

  var data = {
    address: location,
    floors: selectedFloor,
    area: area_string,
    price: price,
    demo: userMail.value,
    clientId: clientId,
    coords: locCoords
  };

  axios({
    method: 'post',
    url: '/send',
    data: data
  });
}

clientId = generatePassword();

assignClientId();

function assignClientId() {
  if (window.ga && ga.create) {
    ga(function(tracker) {
      newClientId = tracker.get('clientId');
      if (newClientId === undefined || newClientId === null || newClientId === '') {
        // Do nothing
      } else {
        clientId = newClientId;
      }
    });
  }
}


// Sanity check
if (area_m2 === undefined) {
  getPriceBtn.setAttribute('disabled', true);
}

// Set up the Drawing Manager
drawingManager.setMap(map);
// Google Listeners
google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {

  allOverlays.push(e);
  if (e.type != google.maps.drawing.OverlayType.MARKER) {
    // Switch back to non-drawing mode after drawing a shape.
    drawingManager.setDrawingMode(null);
    // Add an event listener that selects the newly-drawn shape when the user
    // mouses down on it.
    var newShape = e.overlay;
    newShape.type = e.type;

    google.maps.event.addListener(newShape, 'click', function() {
      setSelection(newShape);
    });

    var drawingBounds = newShape.getPath().b;
    drawingBounds.forEach(function(elm) {
      var LatLng = new google.maps.LatLng(elm.lat(), elm.lng());
      locations.push(LatLng);
    });

    locCoords = locations.map(function(elm) {
      var lat = elm.lat();
      var lng = elm.lng();
      return [lat, lng];

    });

    area_m2 = Math.round(google.maps.geometry.spherical.computeArea(newShape.getPath()));

    getPriceBtn.removeAttribute('disabled');

    setSelection(newShape);

    getPrice();

    sqFt = Math.round(convertToSquareFoot());
    areaElm.textContent = sqFt.toLocaleString('en-US', { minimumFractionDigits: 0 });

  }
});

// Send Analytics
function analyticsCalculate() {
  if (window.ga && ga.create) {
    ga('send', 'event', 'Price Calculator', 'Calculate', address.value, lowerPrice);
  }
}

function analyticsSearch() {
  if (window.ga && ga.create) {
    ga('send', 'event', 'Price Calculator', 'Search', address.value);
  }
}

function analyticsViewDemo() {
  if (window.ga && ga.create) {
    ga('send', 'event', 'Price Calculator', 'View Demo', address.value, userMail.value);
  }
}


// DOM Event Listeners

getPriceBtn.addEventListener('click', function(e) {
  getSiblings(calcWrapper);
  priceElm.textContent = price;
  analyticsCalculate();
  makePostRequest();
});

startOverBtn.addEventListener('click', function(e) {
  getSiblings(resultContainer);
  thanksMsg.classList.remove('is-visible');
  errorMsg.classList.remove('is-visible');
});

clearDrawingsBtn.addEventListener('click', function() {
  deleteAllShape();
});

floorSelector.addEventListener('change', function() {
  selectedFloor = this.options[this.selectedIndex].value;
  getPrice();
  analyticsCalculate();
});

switcher.addEventListener('change', function() {
  var areaHasBeenDrawn = sqFt !== undefined || area_m2 !== undefined;
  if (areaHasBeenDrawn && switcher.checked === true) {
    areaElm.textContent = area_m2.toLocaleString('en-US', { minimumFractionDigits: 0 });
  } else if ((areaHasBeenDrawn && switcher.checked === false)) {
    areaElm.textContent = sqFt.toLocaleString('en-US', { minimumFractionDigits: 0 });
  }
});

searchLocation.addEventListener('click', function() {
  goToLocation();
  analyticsSearch();
});

drawLine.addEventListener('click', function() {
  drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
});

demoRequest.addEventListener('click', function(e) {
  makePostRequest();
  analyticsViewDemo();

  var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (userMail.value.match(pattern)) {
    errorMsg.classList.remove('is-visible');
    thanksMsg.classList.add('is-visible');
    userMail.value = '';
  } else {
    errorMsg.classList.add('is-visible');
  }

});

function initAutocomplete() {
  // Create the search box and link it to the UI element.
  address.addEventListener('keydown', function(e) {
    if (e.which == 13) {
      e.preventDefault();
      goToLocation();
      analyticsSearch();

      return false;
    }
  });

  // Bias the SearchBox results towards current map's viewport.
  map.addListener('bounds_changed', function() {
    searchBox.setBounds(map.getBounds());
  });

  var markers = [];
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener('places_changed', function() {
    var places = searchBox.getPlaces();
    analyticsSearch();

    if (places.length == 0) {
      return;
    }

    // Clear out the old markers.
    markers.forEach(function(marker) {
      marker.setMap(null);
    });
    markers = [];

    // For each place, get the icon, name and location.
    var bounds = new google.maps.LatLngBounds();
    places.forEach(function(place) {
      if (!place.geometry) {
        console.log('Returned place contains no geometry');
        return;
      }

      // Create a marker for each place.
      markers.push(new google.maps.Marker({
        map: map,
        title: place.name,
        position: place.geometry.location
      }));

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });
}

initAutocomplete();
